import React, { useCallback, useRef, useState } from "react";
import { Popup } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { Note, type ExchangeZone, type MarkerObj } from "./Map";
import { Button, Carousel, Modal } from "react-daisyui";

type BlPopupProps = {
  pos: ExchangeZone | MarkerObj;
  zoomLevel: number;
  setModalChildren: (children: React.ReactNode) => void;
};

type PhotoGalleryProps = {
  images: Array<string>;
};

function PhotoGallery({ images }: PhotoGalleryProps) {
  const [selectedImage, setSelectedImage] = useState(0);
  const ref = useRef<HTMLDialogElement>(null);
  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  const showImage = (index: number) => {
    setSelectedImage(index);
    handleShow();
  }

  return (
    <>
      <Carousel display="numbered" className="rounded-box">
        {images.map((image, index) => (
          <Carousel.Item src={image} key={image + index} onClick={() => showImage(index)}/>
        ))}
      </Carousel>
      <Modal ref={ref} backdrop={true} >
        <form method="dialog">
          <Button size="lg" color="ghost" shape="circle" className="absolute top-0 right-0">
            X
          </Button>
        </form>
        <img className="object-scale-down" style={{maxHeight: "calc(100vh-12)"}} src={images[selectedImage]} />
        <Modal.Body>
          
        </Modal.Body>
      </Modal>
    </>
  );
}

type BlNoteProps = {
  note: Note;
};

export function BlNote({ note }: BlNoteProps) {
  const update = new Date(note.display_date !== null ? note.display_date : note.updated || "");

  return (
    <div>
      <h1 className="text-lg">{note.name}</h1>
      <h6>
        <FontAwesomeIcon icon={faCalendarDays} className="px-1" />
        {update.toLocaleString()}
      </h6>
      <p>{note.text}</p>
      {note.images.length > 0 && <PhotoGallery images={note.images} />}
      <p className="break-words text-xs">
        Source:{" "}
        <a href={note.source_url} target="_blank" rel="noopener noreferrer">
          {note.source_url}
        </a>
      </p>
    </div>
  );
}

const buttonStyle =
  "bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white p-1 border border-blue-500 hover:border-transparent rounded";
const disabledButtonStyle = "bg-transparent text-gray-300 font-semibold p-1 border border-gray-300 rounded";

export default function BlPopup({ pos, setModalChildren }: BlPopupProps) {
  const [currentNote, setCurrentNote] = useState(0);

  return (
    <div style={{maxHeight:"90vh"}}>
    <Popup maxWidth={500} minWidth={400} keepInView={false} autoPan={false}>
      <div style={{ maxHeight: "calc(100vh-30)" }}>
        <h1 className="text-lg font-semibold">{pos.name}</h1>
        <hr />
        {pos.notes.length > 0 ? (
          <>
            <BlNote note={pos.notes[currentNote]} />
            <div className="flex items-center gap-2 justify-center">
              <Button
                
                disabled={currentNote === 0}
                onClick={() => setCurrentNote((cNote) => cNote - 1)}
                size="sm"
              >
                &lt;
              </Button>
              <h6 className="text-center text-xl p-1">
                {currentNote + 1} / {pos.notes.length}
              </h6>
              <Button
                disabled={currentNote === pos.notes.length - 1}
                
                onClick={() => setCurrentNote((cNote) => cNote + 1)}
                size="sm"
              >
                &gt;
              </Button>
            </div>
          </>
        ) : (
          <p className="text-md text-center">No information</p>
        )}
      </div>
    </Popup>
    </div>
  );
}
