import React, { useState } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Header from "./Header";
import Markers from "./Markers";
import Segment from "./Segment";
import Modal from "./Modal";
import ExchangeZone from "./ExchangeZone";

export type Note = {
  id: string;
  created?: string;
  updated?: string;
  display_date: string | null;
  name: string;
  source_url: string;
  text: string;
  images: Array<string>;
  exchange_zone_id?: string | null;
  marker_id?: string | null;
};

export type MarkerObj = {
  lat: number;
  lon: number;
  name: string;
  notes: Array<Note>;
  id?: string;
};

export type ExchangeZone = {
  ez_number: number;
} & MarkerObj;

export type MapProps = {
  segments: Array<string>;
  markers: Array<MarkerObj>;
  exchange_zone: Array<ExchangeZone>;
};

export type RouteResponse = {
  geometry: string;
};

export default function Map({ segments, markers, exchange_zone }: MapProps) {
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(undefined);
  return (
    <div className="h-screen w-screen">
      {/*modalChildren !== undefined && <Modal closeModal={() => setModalChildren(undefined)}>{modalChildren}</Modal> */}
      <Header setModalChildren={setModalChildren} />
      <MapContainer
        center={[36.62269611254816, -81.50414651499553]}
        zoom={10}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{ height: "calc(100vh - 2.5rem", width:"100vw" }}
      >
        <ZoomControl position="bottomleft" />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Segment segments={segments} />
        <Markers markers={markers} setModalChildren={setModalChildren} />
        <ExchangeZone exchange_zone={exchange_zone} setModalChildren={setModalChildren} />
      </MapContainer>
    </div>
  );
}
