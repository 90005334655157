import React, { useState } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import { type MarkerObj } from "./Map";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import BlMarker from "./BlMarker";

type MarkersProps = {
  markers: Array<MarkerObj>;
  setModalChildren: (children: React.ReactNode) => void;
};

export default function Markers({ markers, setModalChildren }: MarkersProps) {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  useMapEvent("zoom", () => {
    setZoomLevel(map.getZoom());
  });

  return (
    <>
      {markers.map((pos, index) => (
        <BlMarker
          pos={pos}
          key={pos.toString() + index}
          zoomLevel={zoomLevel}
          icon={faCamera}
          setModalChildren={setModalChildren}
        />
      ))}
    </>
  );
}
