import React from "react";
import { Marker } from "react-leaflet";
import { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { type ExchangeZone, type MarkerObj } from "./Map";
import { renderToString } from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import BlPopup from "./BlPopup";

const createScaledMarker = (pos: ExchangeZone | MarkerObj, zoomLevel: number, icon: IconProp) => {
  const largeIcon = zoomLevel > 10;
  // const icon = pos.ez_number === 0 ? faFlag : pos.name === "Finish" ? faFlagCheckered : faRightLeft;
  const color = pos.notes.length > 0 ? "#BB0000AA" : "#AAAAAAAA";
  const size = largeIcon ? "2x" : "lg";
  const divSize = largeIcon ? "36px" : "24px";
  const countSize = largeIcon ? "24px" : "16px";
  const padding = largeIcon ? "6px" : "4px";
  const notesCount = pos.notes.length > 9 ? "9+" : pos.notes.length.toString();
  const iconHTML = renderToString(
    <div
      style={{
        backgroundColor: color,
        width: divSize,
        height: divSize,
        borderRadius: "50%",
      }}
    >
      <div style={{ padding: padding }}>
        <FontAwesomeIcon icon={icon} size={size} color={"white"} />
      </div>
      {pos.notes.length > 0 && (
        <div
          style={{
            backgroundColor: "blue",
            borderRadius: "50%",
            position: "relative",
            width: countSize,
            height: countSize,
            top: largeIcon ? "-48px" : "-30px",
            right: largeIcon ? "-20px" : "-12px",
            textAlign: "center",
            paddingTop: largeIcon ? "3px" : 0,
          }}
        >
          <p style={{ margin: "auto", color: "white" }}>
            <strong>{largeIcon ? notesCount : <small>{notesCount}</small>}</strong>
          </p>
        </div>
      )}
    </div>,
  );
  const customMarkerIcon = new DivIcon({
    html: iconHTML,
    iconSize: zoomLevel > 10 ? [36, 36] : [24, 24],
    className: "div-icon-transparent",
  });
  return customMarkerIcon;
};

type BlMarkerProps = {
  pos: ExchangeZone | MarkerObj;
  zoomLevel: number;
  icon: IconProp;
  setModalChildren: (children: React.ReactNode) => void;
};

export default function BlMarker({ pos, zoomLevel, icon, setModalChildren }: BlMarkerProps) {
  return (
    <Marker position={[pos.lat, pos.lon]} icon={createScaledMarker(pos, zoomLevel, icon)}>
      <BlPopup pos={pos} zoomLevel={zoomLevel} setModalChildren={setModalChildren} />
    </Marker>
  );
}
