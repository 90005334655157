import React, { useState, useEffect } from "react";
import Map, { ExchangeZone, MarkerObj, RouteResponse } from "../components/Map";



const baseURL = 
  process.env.NODE_ENV === "production"
    ? "https://brr-api.dominiczak.dev"
    : "http://localhost:5002";

export default function MapPage() {
  const [segments, setSegments] = useState<Array<string>>([]);
  const [markers, setMarkers] = useState<Array<MarkerObj>>([]);
  const [exchangeZones, setExchangeZones] = useState<Array<ExchangeZone>>([]);

  useEffect(() => {
    fetch(baseURL + "/route")
      .then((response) => response.json())
      .then((data: Array<RouteResponse>) => {
        setSegments(data.map((seg) => seg["geometry"]));
      });
  }, []);

  useEffect(() => {
    fetch(baseURL + "/exchange_zones")
      .then((response) => response.json())
      .then((data: Array<ExchangeZone>) => {
        setExchangeZones(data);
      });
  }, []);

  useEffect(() => {
    fetch(baseURL + "/marker")
      .then((response) => response.json())
      .then((data: Array<MarkerObj>) => {
        setMarkers(data);
      });
  }, []);

  return (
      <Map segments={segments} markers={markers} exchange_zone={exchangeZones} />
  );
}
