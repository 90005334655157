import React from "react";
import AboutPage from "./AboutPage";

const buttonStyle =
  "bg-transparent hover:bg-blue-500 text-white-700 font-semibold hover:text-white m-1 p-1 border border-white-500 hover:border-transparent rounded uppercase";

type HeaderProps = {
  setModalChildren: (children: React.ReactNode) => void;
};

export default function Header({ setModalChildren }: HeaderProps) {
  const openAboutPage = () => {
    setModalChildren(<AboutPage />);
  };

  return (
    <div className="h-10 flex bg-blue-900 align-middle">
      <h1 className="text-2xl grow pl-3 text-white">Blue Ridge Relay - Helene Impacts</h1>
      <AboutPage />
      {/* 
      <button className={buttonStyle} onClick={openAboutPage}>
        About
      </button>
      */}
    </div>
  );
}
