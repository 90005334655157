import React, { useState, useMemo } from "react";
import { Polyline } from "react-leaflet";
import { type LatLngExpression } from "leaflet";
import PolylineDecode from "@mapbox/polyline";
import "leaflet/dist/leaflet.css";

type SegmentsProps = {
  segments: Array<string>;
};

export default function Segments({ segments }: SegmentsProps) {
  const [segmentPoints, setSegmentPoints] = useState<Array<Array<LatLngExpression>>>([]);

  useMemo(() => {
    setSegmentPoints(
      segments.map((seg) => {
        return PolylineDecode.decode(seg, 6);
      }),
    );
  }, [segments, setSegmentPoints]);

  return (
    <>
      {segmentPoints.map((points) => (
        <Polyline positions={points} key={points.toString()} />
      ))}
    </>
  );
}
