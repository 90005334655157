import React, { useCallback, useRef } from "react";
import { Button, Modal } from "react-daisyui";

/*
export default function AboutPage() {
  return (
    <div className="flex flex-row min-h-screen min-w-screen justify-center items-center w-screen">
      <div className="bg-white p-16 text-gray-950">
        <h1 className="text-5xl">About</h1>
        <hr className="text-gray-800" />
        <br />
        <p className="text-md">
          We are saddened by the incredible impact Hurricane Helene has had on the mountians of Western North Carolina.
          Our team (We thought this was a bar crawl) fell in love with all the communities that made the Blue Ridge
          Ridge Relay possible.
        </p>
        <br />
        <p className="text-md">
          We are including all updates that the{" "}
          <a className="underline" href="https://www.blueridgerelay.com/Race/blueridgerelay/Page/Helene-Relief">
            BRR staff has been posting to their website.
          </a>
        </p>
        <br />
        <p className="text-md">
          If you have updates you would like posted, please send them to me at{" "}
          <a className="underline" href="mailto:jdominiczak+brr@gmail.com">
            jdominiczak+brr@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
}
*/

export default function AboutPage() {
  const ref = useRef<HTMLDialogElement>(null);
  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  return (
    <div className="pr-1 pt-0.5">
      <Button onClick={handleShow} color="primary" size="sm">About</Button>
      <Modal ref={ref} backdrop={true}>
        <form method="dialog">
          <Button size="sm" color="ghost" shape="circle" className="absolute top-2 right-2">
            x
          </Button>
        </form>
        <Modal.Header className="font-bold">About</Modal.Header>
        <Modal.Body>
          <p className="text-md">
            We are saddened by the incredible impact Hurricane Helene has had on the mountians of Western North Carolina.
            Our team (We thought this was a bar crawl) fell in love with all the communities that made the Blue Ridge
            Ridge Relay possible.
          </p>
          <br />
          <p className="text-md">
            We are including all updates that the{" "}
            <a className="underline" href="https://www.blueridgerelay.com/Race/blueridgerelay/Page/Helene-Relief">
              BRR staff has been posting to their website.
            </a>
          </p>
          <br />
          <p className="text-md">
            If you have updates you would like posted, please send them to me at{" "}
            <a className="underline" href="mailto:jdominiczak+brr@gmail.com">
              jdominiczak+brr@gmail.com
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  )
}