import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MapPage from "./Map/MapPage";
//import Admin from "./Admin/Admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MapPage />,
  },/*
  {
    path: "/admin/:view_tab?/:item_id?",
    element: <Admin />,
  },*/
])


export default function App() {
  return (
    <RouterProvider router={router} />
  )
}
