import React, { useState } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { type ExchangeZone } from "./Map";
import { faFlag, faFlagCheckered, faRightLeft } from "@fortawesome/free-solid-svg-icons";
import BlMarker from "./BlMarker";

type MarkersProps = {
  exchange_zone: Array<ExchangeZone>;
  setModalChildren: (children: React.ReactNode) => void;
};

export default function ExchangeZones({ exchange_zone, setModalChildren }: MarkersProps) {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  useMapEvent("zoom", () => {
    setZoomLevel(map.getZoom());
  });

  return (
    <>
      {exchange_zone.map((pos, index) => (
        <BlMarker
          pos={pos}
          key={pos.toString() + index}
          zoomLevel={zoomLevel}
          icon={pos.ez_number === 0 ? faFlag : pos.name === "Finish" ? faFlagCheckered : faRightLeft}
          setModalChildren={setModalChildren}
        />
      ))}
    </>
  );
}
